import React from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

interface PortfolioTableCellProps {
  value: string | undefined;
  delta?: string | undefined;
}
export const PortfolioTableCell = ({ value, delta }: PortfolioTableCellProps) => {
  return (
    <div className="cell">
      <span className="cell-metric-value">{parseFloat(value!).toFixed(1)}</span>
      {delta && delta !== '0' && (
        <span
          style={{
            textAlign: 'end',
            color: parseInt(delta!, 10) > 0 ? 'green' : 'red',
          }}
        >
          {parseInt(delta!, 10) > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
          {`${delta}`}%
        </span>
      )}
      {delta && delta === '0' && <span>{`${delta}`}%</span>}
    </div>
  );
};
