export const columns = {
  revenue: [
    {
      id: 'successful_orders',
    },
    {
      id: 'abv',
    },
    {
      id: 'new_customers',
    },
    {
      id: 'repeat_customers',
    },
    {
      id: 'revenue_loss',
    },
    {
      id: 'gfv',
    },
    { id: 'gmv' },
  ],
  operations: [
    {
      id: 'vendor_failure_rate',
    },
    {
      id: 'vendor_delay_rate',
    },
    {
      id: 'avg_preparation_time',
    },
    {
      id: 'contact_rate',
    },
    {
      id: 'open_time_per',
    },
  ],
  commercial: [
    {
      id: 'ad_revenue',
      children: [
        {
          id: 'cpc_booked',
        },
        {
          id: 'cpc_utilized_percentage',
        },
        {
          id: 'roas_on_cpc_percentage',
        },
        {
          id: 'cpp_revenue',
        },
        {
          id: 'joker_booked',
        },
        {
          id: 'joker_utilized_percentage',
        },
      ],
    },
    {
      id: 'ncr_gmv_percentage',
      children: [
        {
          id: 'cpc_gmv_percentage',
        },
        {
          id: 'cpp_gmv_percentage',
        },
        {
          id: 'joker_gmv_percentage',
        },
      ],
    },
    {
      id: 'ncr_gfv_percentage',
      children: [
        {
          id: 'cpc_gfv_percentage',
        },
        {
          id: 'cpp_gfv_percentage',
        },
        {
          id: 'joker_gfv_percentage',
        },
      ],
    },
  ],
};
