import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { PortfolioTableCell } from '../../components/portfolio-table-cell/portfolio-table-cell';
import { TableColumnHeader } from '../../components/table-column-header/table-column-header';
import { PortfolioOverviewKPIs } from '../../models/kpi.model';
import { getTextTrimToColumnSize } from '../../shared/helper';
import { triggerSalesforceEvent } from '../../shared/useAnalytics';

const columnHelper = createColumnHelper<PortfolioOverviewKPIs>();

export const tabs: {
  [index: string]: string[];
} = {
  commercial: [
    'successful_orders',
    'new_customers',
    'repeat_customers',
    'revenue_loss',
    'abv',
    'gmv',
    'ad_revenue',
    'ncr_gmv_percentage',
    'cpc_gmv_percentage',
    'joker_gmv_percentage',
    'bestseller_gmv_percentage',
    'tpro_gmv_percentage',
    'gfv',
    'ncr_gfv_percentage',
    'cpc_gfv_percentage',
    'joker_gfv_percentage',
  ],
  operational: [
    'vendor_failure_rate',
    'open_time_per',
    'vendor_delay_rate',
    'avg_preparation_time',
    'contact_rate',
  ],
  common: ['serial_no', 'sf_grid_id', 'vendor_name'],
};

export const portfolioPerformanceCols: ColumnDef<PortfolioOverviewKPIs, string>[] = [
  columnHelper.accessor('no', {
    id: 'serial_no',
    size: 30,
    header: () => '',
    cell: (info) => (
      <div className="cell">
        <span>{info.row.index + 1}</span>
      </div>
    ),
  }),
  columnHelper.accessor('sfGridId', {
    id: 'sf_grid_id',
    size: 80,
    header: (info) => <TableColumnHeader value="Grid" info={info} />,
    cell: (info) => <>{info.getValue()}</>,
  }),
  columnHelper.accessor('vendorName', {
    id: 'vendor_name',
    size: 130,
    enableSorting: false,
    header: (info) => <TableColumnHeader value="Account Name" info={info} />,
    cell: (info) => (
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        onClick={() => {
          triggerSalesforceEvent('PortfolioOverview', 'navigation', {
            recordId: info.row.original.salesforceId,
          });
        }}
        className="tooltip"
      >
        <span className="tooltip-text"> {` ${info.getValue()}`}</span>
        <span className="link">
          {getTextTrimToColumnSize(info.getValue(), info.column.getSize(), 9)}
        </span>
      </div>
    ),
  }),

  columnHelper.accessor('successfulOrders', {
    id: 'successful_orders',
    size: 120,
    header: (info) => <TableColumnHeader value="Orders" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('newCustomers', {
    size: 120,
    id: 'new_customers',
    header: (info) => <TableColumnHeader value="Orders By New Customer" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('repeatCustomers', {
    id: 'repeat_customers',
    size: 120,
    header: (info) => <TableColumnHeader value="Orders By Repeat Customer" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('abv', {
    id: 'abv',
    size: 120,
    header: (info) => <TableColumnHeader value="ABV" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('revenueLoss', {
    id: 'revenue_loss',
    size: 120,
    header: (info) => <TableColumnHeader value="Lost Revenue Due To Cancellation " info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),

  columnHelper.accessor('contactRate', {
    id: 'contact_rate',
    size: 120,
    header: (info) => <TableColumnHeader value="Contact Rate" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('openTime', {
    id: 'open_time_per',
    size: 120,
    header: (info) => <TableColumnHeader value="Open Time" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('vendorDelayRate', {
    id: 'vendor_delay_rate',
    size: 120,
    header: (info) => <TableColumnHeader value="Vendor Delay" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('vendorFailureRate', {
    id: 'vendor_failure_rate',
    size: 120,
    header: (info) => <TableColumnHeader value="Vendor Failure" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('gmv', {
    id: 'gmv',
    size: 120,
    header: (info) => <TableColumnHeader value="GMV" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('adRevenue', {
    id: 'ad_revenue',
    size: 120,
    header: (info) => <TableColumnHeader value="Ad Revenue" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('ncrGmvPercentage', {
    id: 'ncr_gmv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="NCR/GMV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('cpcGmvPercentage', {
    id: 'cpc_gmv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="CPC/GMV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('jokerGmvPercentage', {
    id: 'joker_gmv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="Joker/GMV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('bestsellerGmvPercentage', {
    id: 'bestseller_gmv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="BestSeller/GMV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('tproGmvPercentage', {
    id: 'tpro_gmv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="Tpro/GMV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('avgPreparationTime', {
    id: 'avg_preparation_time',
    size: 120,
    header: (info) => <TableColumnHeader value="Avg. Preparation Time" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('gfv', {
    id: 'gfv',
    size: 120,
    header: (info) => <TableColumnHeader value="GFV" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('ncrGfvPercentage', {
    id: 'ncr_gfv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="NCR/GFV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('cpcGfvPercentage', {
    id: 'cpc_gfv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="CPC/GFV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
  columnHelper.accessor('jokerGfvPercentage', {
    id: 'joker_gfv_percentage',
    size: 120,
    header: (info) => <TableColumnHeader value="Joker/GFV%" info={info} />,
    cell: (info) => <PortfolioTableCell value={info.getValue()} />,
  }),
];
