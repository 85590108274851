import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { KPIConfig } from '../../models/kpi.model';

export const useKpiConfig = (
  isInvalidSession: boolean,
  vdToken: string,
  geId: string | undefined,
) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  const {
    isLoading,
    isError,
    data: kpiConfig,
  } = useQuery<KPIConfig[], Error>(
    ['kpi-config', geId],
    async () => {
      const { data } = await axios.request({
        method: 'get',
        url: `/vendor-dashboard-service/kpi-configs?geId=${geId}`,
        headers: {
          'x-vendorDashboard-token': vdToken,
        },
      });
      return data.data;
    },
    { enabled: !!vdToken && !isInvalidSession && !!geId },
  );
  return { kpiConfig, kpiConfigLoading: isLoading, kpiConfigError: isError };
};
