import { useEffect, useReducer } from 'react';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Option } from 'react-multi-select-component';
import { AccountManagerHierarchy, SubOrdinates } from '../../models/am-hierarchy.model';
import { AMC_VIEWS } from '../../models/constants';
import { MomentDate } from '../../models/date.model';
import { Filter } from '../../shared/helper';

interface FilterState {
  isResetButtonDisabled: boolean;
  dateOption: string;
  dateTitle: string;
  country: string;
  tlOption: string;
  tlTitle: string;
  amOption: string;
  amTitle: string;
  verticals: Option[];
  brands: Option[];
  groups: Option[];
  cities: Option[];
}
export const useFilterState = (
  quaterFilterOptions: Filter,
  monthFilterOptions: Filter,
  accountManagerHierarchy: AccountManagerHierarchy | null,
  view: string,
  onRangeChange: (from: MomentDate, to: MomentDate, precision: 'month' | 'quarter' | 'day') => void,
  onTargetedAMsChange: (targetedAMs: string[]) => void,
  onFiltersChange: (filter: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  }) => void,
) => {
  const initialFilterState: FilterState = {
    isResetButtonDisabled: true,
    dateOption: 'QTD',
    dateTitle: quaterFilterOptions.QTD.label,
    country: accountManagerHierarchy?.countries ? accountManagerHierarchy?.countries[0] : '',
    tlOption: '',
    tlTitle: '',
    amOption: '',
    amTitle: '',
    verticals: [],
    brands: [],
    groups: [],
    cities: [],
  };
  const allAMs: SubOrdinates[] = [];
  if (view === AMC_VIEWS.TEAM_LEAD) {
    accountManagerHierarchy?.subOrdinates?.map((am) => allAMs.push(am));
  } else if (view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
    accountManagerHierarchy?.subOrdinates?.forEach((tl) => {
      tl?.subOrdinates?.map((am) => allAMs.push(am));
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterReducer = (state: FilterState, action: { type: string; payload: any }) => {
    const { type, payload } = action;
    switch (type) {
      case 'update_date': {
        if (Object.keys(monthFilterOptions).includes(payload)) {
          return {
            ...state,
            dateOption: payload,
            dateTitle: monthFilterOptions[payload].label,
            isResetButtonDisabled: false,
          };
        }

        return {
          ...state,
          dateOption: payload,
          dateTitle: quaterFilterOptions[payload].label,
          isResetButtonDisabled: false,
        };
      }
      case 'update_country': {
        return {
          ...state,
          country: payload,
          tlOption: '',
          tlTitle: '',
          amOption: '',
          amTitle: '',
          isResetButtonDisabled: false,
        };
      }
      case 'update_tl': {
        return {
          ...state,
          tlOption: payload,
          tlTitle:
            accountManagerHierarchy?.subOrdinates?.find((itm) => itm?.id === payload)?.name || '',
          amOption: '',
          amTitle: '',
          isResetButtonDisabled: false,
        };
      }
      case 'update_am': {
        return {
          ...state,
          amOption: payload,
          amTitle: allAMs?.find((itm) => itm?.id === payload)?.name || '',
          isResetButtonDisabled: false,
        };
      }
      case 'update_vertical': {
        return {
          ...state,
          verticals: payload,
          isResetButtonDisabled: false,
        };
      }
      case 'update_brand': {
        return {
          ...state,
          brands: payload,
          isResetButtonDisabled: false,
        };
      }
      case 'update_group': {
        return {
          ...state,
          groups: payload,
          isResetButtonDisabled: false,
        };
      }
      case 'update_city': {
        return {
          ...state,
          cities: payload,
          isResetButtonDisabled: false,
        };
      }
      default:
        return initialFilterState;
    }
  };
  const [
    {
      dateOption,
      dateTitle,
      country,
      tlOption,
      tlTitle,
      amOption,
      amTitle,
      isResetButtonDisabled,
      verticals,
      brands,
      groups,
      cities,
    },
    dispatchFilter,
  ] = useReducer(filterReducer, initialFilterState);

  useEffect(() => {
    if (Object.keys(monthFilterOptions).includes(dateOption)) {
      onRangeChange(
        moment(monthFilterOptions[dateOption].start),
        moment(monthFilterOptions[dateOption].end),
        'month',
      );
    } else {
      onRangeChange(
        moment(quaterFilterOptions[dateOption].start),
        moment(quaterFilterOptions[dateOption].end),
        'quarter',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOption]);
  useEffect(() => {
    if (view === AMC_VIEWS.TEAM_LEAD) {
      if (amOption) {
        onTargetedAMsChange([amOption]);
      } else {
        onTargetedAMsChange(accountManagerHierarchy?.subOrdinates?.map((am) => am.id) || []);
      }
    }
    if (view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
      if (amOption) {
        onTargetedAMsChange([amOption]);
      } else {
        let filteredList: SubOrdinates[] | undefined = accountManagerHierarchy?.subOrdinates;
        if (country) {
          filteredList = filteredList?.filter((tl) => tl.country === country);
        }
        if (tlOption) {
          filteredList = filteredList?.filter((tl) => tl.id === tlOption);
        }
        const items: string[] = [];
        filteredList?.forEach((tl) => {
          tl.subOrdinates?.forEach((am) => {
            items.push(am.id);
          });
        });
        onTargetedAMsChange(items);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amOption, country, tlOption, accountManagerHierarchy?.subOrdinates]);
  useEffect(() => {
    onFiltersChange({
      verticals: verticals.map((v: { value: string }) => v.value),
      brands: brands.map((b: { value: string }) => b.value),
      cities: cities.map((c: { value: string }) => c.value),
      groups: groups.map((g: { value: string }) => g.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verticals, brands, cities, groups]);
  return {
    dateOption,
    dateTitle,
    country,
    tlOption,
    tlTitle,
    amOption,
    amTitle,
    dispatchFilter,
    isResetButtonDisabled,
    verticals,
    brands,
    groups,
    cities,
  };
};
