import { MenuProps } from 'antd/es/menu/menu';
import moment, { Moment } from 'moment';

export const getMonthFilterOptions = () => {
  const monthNames = moment.monthsShort();
  const monthFilterOptions: Filter = {};
  const prevMonthDate = moment().date(1).subtract(1, 'months');
  for (let i = 0; i < 12; i++) {
    const start = prevMonthDate.clone();
    const end = prevMonthDate.clone().endOf('month');
    const label = `${start.format('MMM DD, YYYY')} - ${end.format('MMM DD, YYYY')}`;
    monthFilterOptions[`${monthNames[prevMonthDate.month()]} ${prevMonthDate.year()}`] = {
      start,
      end,
      label,
    };
    prevMonthDate.subtract(1, 'months').date(1);
  }
  return monthFilterOptions;
};

export const getMonthFilterOptionsFromCurrentMonth = () => {
  const monthNames = moment.monthsShort();
  const monthFilterOptions: Filter = {};
  const currentMonthStart = moment().date(1);
  for (let i = 0; i < 12; i++) {
    const start = currentMonthStart.clone();
    const end = currentMonthStart.clone().endOf('month');
    const label = `${start.format('MMM DD, YYYY')} - ${end.format('MMM DD, YYYY')}`;
    monthFilterOptions[`${monthNames[currentMonthStart.month()]} ${currentMonthStart.year()}`] = {
      start,
      end,
      label,
    };
    currentMonthStart.subtract(1, 'months').date(1);
  }
  return monthFilterOptions;
};

export const getquarterFilterOptions = () => {
  const now = new Date();
  const quarter = Math.floor(now.getMonth() / 3);

  const quarterFilterOptions: Filter = {};
  let from = moment([now.getFullYear(), quarter * 3, 1]);
  let to = moment().endOf('D');
  quarterFilterOptions.QTD = {
    start: from,
    end: to,
    label: `${from?.format('ll')} - ${to.format('ll')}`,
  };
  for (let i = quarter - 1; i >= 0; i--) {
    from = moment([now.getFullYear(), i * 3, 1]);
    to = moment([from.year(), from.month() + 2, 1]).endOf('M');
    quarterFilterOptions[`Q${i + 1} ${moment().year()} `] = {
      start: from,
      end: to,
      label: `${from?.format('ll')} - ${to.format('ll')}`,
    };
  }
  for (let i = 3; i > quarter; i--) {
    from = moment([now.getFullYear() - 1, i * 3, 1]);
    to = moment([from.year(), from.month() + 2, 1]).endOf('M');
    quarterFilterOptions[`Q${i + 1} ${moment().year() - 1} `] = {
      start: from,
      end: to,
      label: `${from?.format('ll')} - ${to.format('ll')}`,
    };
  }
  return quarterFilterOptions;
};

export type MenuItem = Required<MenuProps>['items'][number];

export function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: 'light' | 'dark',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}
export type Filter = {
  [key: string]: {
    start: Moment;
    end: Moment;
    label: string;
  };
};
export function isLatinString(s: string) {
  let i;
  let charCode;
  for (i = s?.length; i--; ) {
    charCode = s.charCodeAt(i);
    if (charCode < 32 || charCode > 255) return false;
  }
  return true;
}
export function getTextTrimToColumnSize(text: string, size: number, divisor: number) {
  return `${text?.substring(0, size / (isLatinString(text) ? divisor : divisor + 3))}
               ${text?.length > size / (isLatinString(text) ? divisor : divisor + 3) ? '...' : ''}`;
}
