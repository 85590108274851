/* eslint-disable max-lines */
import React, { ReactElement, useMemo, useState } from 'react';
import { CaretDownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { MultiSelect, Option } from 'react-multi-select-component';
import { FilterDropDown } from '../../elements/dropDown';
import { Account } from '../../models/account';
import { AccountManagerHierarchy, SubOrdinates } from '../../models/am-hierarchy.model';
import { AMC_VIEWS } from '../../models/constants';
import { MomentDate } from '../../models/date.model';
import { useTranslations } from '../../services/i18n/useTranslations';
import './portfolio-performance.scss';
import {
  Filter,
  getquarterFilterOptions,
  getMonthFilterOptionsFromCurrentMonth,
  MenuItem,
  getItem,
} from '../../shared/helper';
import { useFilterState } from './useFilterState';

interface PortfolioPerformanceProps {
  onTargetedAMsChange: (targetedAMs: string[]) => void;
  onFiltersChange: (filter: {
    verticals: string[];
    brands: string[];
    groups: string[];
    cities: string[];
  }) => void;
  children: ReactElement;
  accountManagerHierarchy: AccountManagerHierarchy | null;
  isFilterDisabled: boolean;
  onRangeChange: (from: MomentDate, to: MomentDate, precision: 'month' | 'quarter' | 'day') => void;
  onTabChange: (tabValue: 'commercial' | 'operational') => void;
  onSearch: (searchText: string) => void;
  view: string;
  filterData: {
    verticals: string[] | undefined;
    brands: Account[] | undefined;
    groups: Account[] | undefined;
    cities: string[] | undefined;
  };
}

const EmptySpace = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
const ArrowRenderer = () => <CaretDownOutlined style={{ paddingLeft: '5px' }} />;

const CustomClearSelectionIcon = () => <CloseOutlined style={{ paddingLeft: '5px' }} />;

export const PortfolioPerformance = ({
  children,
  onTargetedAMsChange,
  onFiltersChange,
  accountManagerHierarchy,
  isFilterDisabled,
  onRangeChange,
  onTabChange,
  onSearch,
  view,
  filterData,
}: PortfolioPerformanceProps) => {
  const { t } = useTranslations();

  const [tab, setTab] = useState('commercial');

  const quaterFilterOptions: Filter = getquarterFilterOptions();

  const monthFilterOptions: Filter = getMonthFilterOptionsFromCurrentMonth();

  const {
    verticals,
    brands,
    groups,
    cities,
    dateOption,
    dateTitle,
    country,
    tlOption,
    tlTitle,
    amOption,
    amTitle,
    dispatchFilter,
    isResetButtonDisabled,
  } = useFilterState(
    quaterFilterOptions,
    monthFilterOptions,
    accountManagerHierarchy,
    view,
    onRangeChange,
    onTargetedAMsChange,
    onFiltersChange,
  );

  const countryDropdownItems: MenuItem[] = useMemo(() => {
    if (accountManagerHierarchy?.countries?.length && view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
      return accountManagerHierarchy.countries.map((key) =>
        getItem(key, key, key === country ? <CheckOutlined /> : <EmptySpace />),
      );
    }
    return [];
  }, [accountManagerHierarchy?.countries, view, country]);

  const tlDropdownitems: MenuItem[] = useMemo(() => {
    if (accountManagerHierarchy?.subOrdinates?.length) {
      if (view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
        let filteredList: SubOrdinates[] | null = accountManagerHierarchy?.subOrdinates;
        if (country) {
          filteredList = accountManagerHierarchy?.subOrdinates.filter(
            (tl) => tl.country === country,
          );
        }
        return filteredList.map((tl) => {
          return getItem(tl.name, tl.id, tl.id === tlOption ? <CheckOutlined /> : <EmptySpace />);
        });
      }
    }
    return [];
  }, [accountManagerHierarchy?.subOrdinates, tlOption, country, view]);

  const amDropdownItems: MenuItem[] = useMemo(() => {
    if (accountManagerHierarchy?.subOrdinates?.length) {
      if (view === AMC_VIEWS.TEAM_LEAD) {
        return accountManagerHierarchy.subOrdinates.map((am) => {
          return getItem(am.name, am.id, am.id === amOption ? <CheckOutlined /> : <EmptySpace />);
        });
      }
      if (view === AMC_VIEWS.REGIONAL_TEAM_LEAD) {
        let filteredList: SubOrdinates[] | null = accountManagerHierarchy?.subOrdinates;

        if (country) {
          filteredList = accountManagerHierarchy.subOrdinates.filter(
            (tl) => tl.country === country,
          );
        }
        if (tlOption) {
          filteredList = filteredList.filter((tl) => tl.id === tlOption);
        }
        const items: MenuItem[] = [];
        filteredList.forEach((tl) => {
          tl.subOrdinates?.forEach((am) => {
            items.push(
              getItem(am.name, am.id, am.id === amOption ? <CheckOutlined /> : <EmptySpace />),
            );
          });
        });
        return items;
      }
      return [];
    }
    return [];
  }, [accountManagerHierarchy?.subOrdinates, amOption, country, tlOption, view]);

  const verticalItems: Option[] = useMemo(() => {
    if (filterData.verticals?.length) {
      return filterData.verticals
        .filter((v) => v)
        .map((v) => {
          return { label: v, value: v };
        });
    }
    return [];
  }, [filterData.verticals]);
  const brandItems: Option[] = useMemo(() => {
    if (filterData.brands?.length) {
      return filterData.brands
        .filter((v) => v)
        .map((v) => {
          return { label: v.name, value: v.id };
        });
    }
    return [];
  }, [filterData.brands]);
  const groupItems: Option[] = useMemo(() => {
    if (filterData.groups?.length) {
      return filterData.groups
        .filter((v) => v)
        .map((v) => {
          return { label: v.name, value: v.id };
        });
    }
    return [];
  }, [filterData.groups]);
  const cityItems: Option[] = useMemo(() => {
    if (filterData.cities?.length) {
      return filterData.cities
        .filter((v) => v)
        .map((v) => {
          return { label: v, value: v };
        });
    }
    return [];
  }, [filterData.cities]);
  const dateDropdownitems: MenuItem[] = useMemo(() => {
    return [
      ...Object.keys(quaterFilterOptions).map((key) =>
        getItem(key, key, key === dateOption ? <CheckOutlined /> : <EmptySpace />),
      ),
      getItem(
        t.VendorDashboardLayoutInThisMonth,
        t.VendorDashboardLayoutInThisMonth,
        <EmptySpace />,
        [
          ...Object.keys(monthFilterOptions).map((key) =>
            getItem(key, key, key === dateOption ? <CheckOutlined /> : <EmptySpace />),
          ),
        ],
        'light',
      ),
    ];
  }, [dateOption, quaterFilterOptions, monthFilterOptions, t.VendorDashboardLayoutInThisMonth]);

  const handleTabClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTab(event.currentTarget.value);
    onTabChange(event.currentTarget.value === 'commercial' ? 'commercial' : 'operational');
  };

  return (
    <div className="portfolio-performance">
      <div className="header">
        <div className="tab-container">
          <button
            type="button"
            value="commercial"
            onClick={handleTabClick}
            className={`tab-text ${tab === 'commercial' ? 'selected-tab' : ''}`}
          >
            Commercial
          </button>
          <button
            type="button"
            value="operational"
            onClick={handleTabClick}
            className={`tab-text ${tab === 'operational' ? 'selected-tab' : ''}`}
          >
            Operational
          </button>
        </div>
        <div className="dropdown">
          <div style={{ display: 'flex', gap: '8px' }}>
            <FilterDropDown
              isFilterDisabled={isFilterDisabled}
              onFilterChange={(payload: string) => {
                dispatchFilter({ type: 'update_date', payload });
              }}
              dropdownitems={dateDropdownitems}
              title={dateTitle}
            />
            {!!countryDropdownItems.length && (
              <FilterDropDown
                isFilterDisabled={isFilterDisabled}
                onFilterChange={(payload: string) => {
                  dispatchFilter({ type: 'update_country', payload });
                }}
                dropdownitems={countryDropdownItems}
                title={country || 'Country'}
              />
            )}
            {!!tlDropdownitems.length && (
              <FilterDropDown
                isFilterDisabled={isFilterDisabled}
                onFilterChange={(payload: string) => {
                  dispatchFilter({ type: 'update_tl', payload });
                }}
                dropdownitems={tlDropdownitems}
                title={tlTitle || 'Team Lead'}
              />
            )}
            {!!amDropdownItems.length && (
              <FilterDropDown
                isFilterDisabled={isFilterDisabled}
                onFilterChange={(payload: string) => {
                  dispatchFilter({ type: 'update_am', payload });
                }}
                dropdownitems={amDropdownItems}
                title={amTitle || 'Account Manager'}
              />
            )}
            {!!verticalItems?.length && (
              <MultiSelect
                options={verticalItems}
                value={verticals}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_vertical', payload });
                }}
                valueRenderer={(selected, _options) => {
                  return selected.length ? `Vertical (${selected.length})` : 'Vertical';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Vertical"
              />
            )}
            {!!brandItems.length && (
              <MultiSelect
                options={brandItems}
                value={brands}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_brand', payload });
                }}
                valueRenderer={(selected, _options) => {
                  return selected.length ? `Brands (${selected.length})` : 'Brands';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Vertical"
              />
            )}
            {!!groupItems.length && (
              <MultiSelect
                options={groupItems}
                value={groups}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_group', payload });
                }}
                valueRenderer={(selected, _options) => {
                  return selected.length ? `Groups (${selected.length})` : 'Groups';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Groups"
              />
            )}
            {!!cityItems.length && (
              <MultiSelect
                options={cityItems}
                value={cities}
                onChange={(payload: Option[]) => {
                  dispatchFilter({ type: 'update_city', payload });
                }}
                valueRenderer={(selected, _options) => {
                  return selected.length ? `Cities (${selected.length})` : 'Cities';
                }}
                ArrowRenderer={ArrowRenderer}
                ClearSelectedIcon={<CustomClearSelectionIcon />}
                labelledBy="Cities"
              />
            )}
            <button
              className="reset-button"
              type="button"
              disabled={isResetButtonDisabled}
              onClick={() => {
                dispatchFilter({ type: 'reset', payload: '' });
              }}
            >
              Reset All
            </button>
          </div>
          <Search
            placeholder="Search Vendor Grid"
            onChange={(e) => onSearch(e.target.value.toUpperCase())}
            style={{ width: 200 }}
          />
        </div>
      </div>
      <div className="container">{children}</div>
    </div>
  );
};
