import { useEffect, useState } from 'react';
import { AccountManagerHierarchy, SalesforceMessage } from '../../models/am-hierarchy.model';
import { REACT_APP_SALESFORCE_URL } from '../../models/env.model';

export const useAccountManagerHierarchy = () => {
  const [accountManagerHierarchy, setAccountManagerHierarchy] =
    useState<AccountManagerHierarchy | null>(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const onMessage = (event: MessageEvent<SalesforceMessage>) => {
      if (event.origin !== REACT_APP_SALESFORCE_URL) return;

      if (event.data.accountManagerHierarchy) {
        setAccountManagerHierarchy(event.data.accountManagerHierarchy);
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);
  return { accountManagerHierarchy };
};
