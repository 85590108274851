import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Account } from '../../models/account';

export const useFilterData = (
  isInvalidSession: boolean,
  vdToken: string,
  accountOwnerIds: string[],
  role: string | undefined,
) => {
  const { data: verticals } = useQuery<string[], Error>(
    ['verticals', accountOwnerIds, vdToken, role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/verticals?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled: !!vdToken && !isInvalidSession && !!accountOwnerIds.length && role !== 'AGENT',
    },
  );

  const { data: brands } = useQuery<Account[], Error>(
    ['brands', accountOwnerIds, vdToken, role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/brands?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled: !!vdToken && !isInvalidSession && !!accountOwnerIds.length && role !== 'AGENT',
    },
  );

  const { data: groups } = useQuery<Account[], Error>(
    ['groups', accountOwnerIds, vdToken, role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/groups?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled: !!vdToken && !isInvalidSession && !!accountOwnerIds.length && role !== 'AGENT',
    },
  );

  const { data: cities } = useQuery<string[], Error>(
    ['cities', accountOwnerIds, vdToken, role],
    async () => {
      const { data } = await axios.get(
        `/vendor-dashboard-service/cities?&accountOwnerIds=${accountOwnerIds}`,
        {
          headers: {
            'x-vendorDashboard-token': vdToken,
          },
        },
      );
      return data.data;
    },
    {
      enabled: !!vdToken && !isInvalidSession && !!accountOwnerIds.length && role !== 'AGENT',
    },
  );

  return {
    filterData: {
      verticals,
      brands,
      groups,
      cities,
    },
  };
};
